export default {
    title: 'Zero Emissions Deliveries',
    location: '/sustainability/zero-emissions',
    tabs: [
        {
            name: 'By Country',
            link: `/sustainability/zero-emissions`
        },
        {
            name: 'Trend',
            link: `/sustainability/zero-emissions/:market`
        }
    ]
}
